import {Button, Card, Col, Row} from "antd";
import {ProductLineRegister} from "./Components/product.line.register";
import {Package} from "./Components/package.register";
import {Validity} from "./Components/validity.register";
import {Campaign} from "./Components/campaign.register";
import {Funcionalidad} from "./Components/funcionalidad.register";
import React, {useEffect, useState} from "react";
import {CatalogsConfig} from "./catalogs.config";
import {useHistory} from "react-router-dom";

export function CatalogRegister() {

    const [keyForm, setKeyForm] = useState('');
    const [configGlobal, setConfigGlobal] = useState({});
    const history = useHistory();

    useEffect(() => {
        const key = sessionStorage.getItem('key');
        if (key) {
            setKeyForm(key);
            setConfigGlobal(CatalogsConfig[key]);
        } else {
            history.goBack();
        }
    }, []);

    const goBack = () => {
      history.goBack();
    }

    return (
        <>
            <Row>
                <Col span={24} className='pt-4 pl-4'>
                    <h2>{configGlobal?.title_register}</h2>
                </Col>
                <Col span={24}>
                    <div className='mb-3 d-flex d-inline-flex justify-content-end w-100'>
                        <Button type="primary" ghost onClick={goBack}>Volver a la lista</Button>
                    </div>
                </Col>
            </Row>
            <Card bodyStyle={{'padding': '0px'}}>
                { (keyForm === 'product-line') && <ProductLineRegister/> }
                { (keyForm === 'package') && <Package/> }
                { (keyForm === 'validity') && <Validity/> }
                { (keyForm === 'campaigns') && <Campaign/> }
                { (keyForm === 'funcionalidad') && <Funcionalidad/> }
            </Card>
        </>
    );
}