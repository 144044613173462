import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_CATALOG, APP_PREFIX_PATH } from 'configs/AppConfig'
import { CatalogComponent } from "./catalogs";
import { CatalogRegister } from "./catalogs/catalog.register";
import {CatalogUpdate} from "./catalogs/catalog.update";

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/users-list`} component={lazy(() => import(`./users-list`))} />
                <Route path={`${APP_PREFIX_PATH}/admin-users-list`} component={lazy(() => import(`./admin-users-list`))} />
                <Route path={`${APP_PREFIX_PATH}/user-detail`} component={lazy(() => import(`./user-detail`))} />
                <Route path={`${APP_PREFIX_PATH}/accounts`} component={lazy(() => import(`./account-list`))} />
                <Route path={`${APP_PREFIX_PATH}/account-detail`} component={lazy(() => import(`./account-detail`))} />
                <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
                <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
                <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./product`))} />
                <Route path={`${APP_PREFIX_PATH}/product-register`} component={lazy(() => import(`./product-register`))} />
                <Route path={`${APP_PREFIX_PATH}/product-update`} component={lazy(() => import(`./product-update`))} />
                <Route path={`${APP_PREFIX_PATH}/product-detail`} component={lazy(() => import(`./product/detail.product`))} />
                <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />

                <Route strict={true} path={`${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/register`} component={CatalogRegister} />
                <Route strict={true} path={`${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/update`} component={CatalogUpdate} />
                <Route path={`${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}`} component={CatalogComponent} />

                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/accounts`} />
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);