import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN, REFRESH_TOKEN,
	SIGNIN, SIGNOUT,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated, signOutSuccess,
} from "../actions/Auth";

import JwtAuthService from "../../services/JwtAuthService";

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const rsp = yield call(JwtAuthService.login, {email, password});
			const user = rsp?.data;
			const roles = user?.data?.authorities;
			const access = roles.find(element => element === 'ROLE_ADMIN'|| element === 'ROLE_COMERCIAL');
			if (access) {
				if (user?.message) {
					yield put(showAuthMessage(user.message));
				} else {
					localStorage.setItem('user', JSON.stringify(user?.data));
					localStorage.setItem(AUTH_TOKEN, user?.access_token);
					localStorage.setItem(REFRESH_TOKEN, user?.refresh_token);
					yield put(authenticated(user.access_token));
				}
			} else {
				yield put(showAuthMessage(user?.message ||'No tienes privilegios para ingresar.'));
			}
		} catch (err) {
			yield put(showAuthMessage(err?.message || 'Verifica tus credenciales de acceso.'));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.clear();
			sessionStorage.clear();
			yield put(signOutSuccess('Te esperamos pronto.'));
			window.location.href = '/';
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithFBEmail),
		fork(signOut),
	]);
}
