import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import {APP_PREFIX_CATALOG, APP_PREFIX_PATH} from "../../configs/AppConfig";
import {
    AlertFilled,
    BarsOutlined, CalendarFilled, CheckSquareFilled,
    DashboardFilled,
    DashboardOutlined,
    RobotOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    AppstoreOutlined,
    ShoppingOutlined
} from "@ant-design/icons";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}

    const [menuEd, setMenuEd] = useState([]);

    useEffect(() => {
        let user = null;
        try {
            user = JSON.parse(localStorage.getItem('user'));
        } catch (e) {
            localStorage.clear();
            window.location.href = "/";
            return;
        }
        if (user && user.authorities && user.authorities[0] !== 'ROLE_COMERCIAL') {
            console.log('ingresa');
            setMenuEd([
                {
                    key: 'admin',
                    title: 'admin',
                    path: `${APP_PREFIX_PATH}/pages`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'admin-user',
                            title: 'sidenav.pages.user',
                            icon: RobotOutlined,
                            path: `${APP_PREFIX_PATH}/accounts`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'user-list',
                            title: 'sidenav.pages.user.list',
                            icon: UserOutlined,
                            path: `${APP_PREFIX_PATH}/users-list`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'admin-user-list',
                            title: 'sidenav.pages.admin.user.list',
                            icon: UsergroupAddOutlined,
                            path: `${APP_PREFIX_PATH}/admin-users-list`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                          key: 'orders-list',
                          title: 'sidenav.pages.orders',
                          icon: ShoppingOutlined,
                          path: `${APP_PREFIX_PATH}/orders`,
                          breadcrumb: true,
                          submenu: []
                        },
                        {
                            key: 'product',
                            path: '',
                            title: 'sidenav.pages.products',
                            icon: DashboardFilled,
                            breadcrumb: true,
                            submenu: [
                                {
                                    key: 'product-list',
                                    path: `${APP_PREFIX_PATH}/products`,
                                    title: 'sidenav.pages.product.list',
                                    icon: '',
                                    breadcrumb: true,
                                    submenu: []
                                },
                                {
                                    key: 'product-register',
                                    path: `${APP_PREFIX_PATH}/product-register`,
                                    title: 'sidenav.pages.product.register',
                                    icon: '',
                                    breadcrumb: true,
                                    submenu: []
                                },
                            ]
                        }
                    ]
                },
                {
                    key: 'catalogs.title.name',
                    title: 'catalogs.title.name',
                    path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'catalogs.title.line.product.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/product-line`,
                            title: 'catalogs.title.line',
                            icon: BarsOutlined,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.package.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/package`,
                            title: 'catalogs.title.package',
                            icon: CheckSquareFilled,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.validity.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/validity`,
                            title: 'catalogs.title.validity',
                            icon: CalendarFilled,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.campaigns.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/campaigns`,
                            title: 'catalogs.title.campaigns',
                            icon: AlertFilled,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                          key: 'catalogs.title.funcionalidad.list',
                          path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/funcionalidad`,
                          title: 'catalogs.title.funcionalidad',
                          icon: AppstoreOutlined,
                          breadcrumb: true,
                          submenu: []
                      },
                    ]
                },
            ]);
        } else {
            setMenuEd([
                {
                    key: 'admin',
                    title: 'admin',
                    path: `${APP_PREFIX_PATH}/pages`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'admin-user',
                            title: 'sidenav.pages.user',
                            icon: RobotOutlined,
                            path: `${APP_PREFIX_PATH}/accounts`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'user-list',
                            title: 'sidenav.pages.user.list',
                            icon: UserOutlined,
                            path: `${APP_PREFIX_PATH}/users-list`,
                            breadcrumb: true,
                            submenu: []
                        }
                    ]
                },
            ]);
        }
    }, []);

    return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {menuEd.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;

    const [menuEd, setMenuEd] = useState([]);

    useEffect(() => {

        let user = null;
        try {
            user = JSON.parse(localStorage.getItem('user'));
        } catch (e) {
            localStorage.clear();
            window.location.href = "/";
            return;
        }

        if (user && user.authorities && user.authorities[0] !== 'ROLE_COMERCIAL') {
            setMenuEd([
                {
                    key: 'admin',
                    title: 'admin',
                    path: `${APP_PREFIX_PATH}/pages`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'admin-user',
                            title: 'sidenav.pages.user',
                            icon: RobotOutlined,
                            path: `${APP_PREFIX_PATH}/accounts`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'user-list',
                            title: 'sidenav.pages.user.list',
                            icon: UserOutlined,
                            path: `${APP_PREFIX_PATH}/users-list`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'admin-user-list',
                            title: 'sidenav.pages.admin.user.list',
                            icon: UsergroupAddOutlined,
                            path: `${APP_PREFIX_PATH}/admin-users-list`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                          key: 'orders-list',
                          title: 'sidenav.pages.orders',
                          icon: ShoppingOutlined,
                          path: `${APP_PREFIX_PATH}/orders`,
                          breadcrumb: true,
                          submenu: []
                        },
                        {
                            key: 'product',
                            path: '',
                            title: 'sidenav.pages.products',
                            icon: DashboardFilled,
                            breadcrumb: true,
                            submenu: [
                                {
                                    key: 'product-list',
                                    path: `${APP_PREFIX_PATH}/products`,
                                    title: 'sidenav.pages.product.list',
                                    icon: '',
                                    breadcrumb: true,
                                    submenu: []
                                },
                                {
                                    key: 'product-register',
                                    path: `${APP_PREFIX_PATH}/product-register`,
                                    title: 'sidenav.pages.product.register',
                                    icon: '',
                                    breadcrumb: true,
                                    submenu: []
                                },
                            ]
                        }
                    ]
                },
                {
                    key: 'catalogs.title.name',
                    title: 'catalogs.title.name',
                    path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'catalogs.title.line.product.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/product-line`,
                            title: 'catalogs.title.line',
                            icon: BarsOutlined,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.package.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/package`,
                            title: 'catalogs.title.package',
                            icon: CheckSquareFilled,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.validity.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/validity`,
                            title: 'catalogs.title.validity',
                            icon: CalendarFilled,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'catalogs.title.campaigns.list',
                            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/campaigns`,
                            title: 'catalogs.title.campaigns',
                            icon: AlertFilled,
                            breadcrumb: true,
                            submenu: []
                        }
                    ]
                },
            ]);
        } else {
            setMenuEd([
                {
                    key: 'admin',
                    title: 'admin',
                    path: `${APP_PREFIX_PATH}/pages`,
                    icon: DashboardOutlined,
                    breadcrumb: true,
                    submenu: [
                        {
                            key: 'admin-user',
                            title: 'sidenav.pages.user',
                            icon: RobotOutlined,
                            path: `${APP_PREFIX_PATH}/accounts`,
                            breadcrumb: true,
                            submenu: []
                        },
                        {
                            key: 'user-list',
                            title: 'sidenav.pages.user.list',
                            icon: UserOutlined,
                            path: `${APP_PREFIX_PATH}/users-list`,
                            breadcrumb: true,
                            submenu: []
                        }
                    ]
                },
            ]);
        }
    }, []);

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {menuEd?.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
