import axios from 'axios'
import {API_BASE_URL} from 'configs/AppConfig'
import history from '../history'
import {AUTH_TOKEN, REFRESH_TOKEN} from 'redux/constants/Auth'
import {notification} from 'antd';
import {env} from "../configs/EnvironmentConfig";
import {authenticated} from "../redux/actions/Auth";
// import {useHistory} from "react-router-dom";

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// const history = useHistory();

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${ jwtToken }`;
	}

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		// window.location.reload();
	}

	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
let isRefreshing = false;
let refreshSubscribers = [];
service.interceptors.response.use( (response) => {
	return response
}, async (error) => {

	const { config, response: { status, data } } = error;
	const originalRequest = config;

	// Remove token and redirect
	if (status === 401) {
		if (!isRefreshing) {
			isRefreshing = true;
			const refresh_token = localStorage.getItem(REFRESH_TOKEN);
			if (refresh_token === "undefined" || refresh_token === undefined || refresh_token === null || refresh_token === '') {
				console.log('ingresa a que no existe el token');
				localStorage.clear();
				window.location = '/';
				return Promise.reject(data);
			}
			fetch(`${ env.API_ENDPOINT_URL }/security/oauth/token`, {
				method: 'POST',
				body: 'grant_type=refresh_token&refresh_token=' + refresh_token,
				headers:{
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': 'Basic ' + btoa("LAAS:LEXIS_SERVICE_LAAS")
				}
			}).then(res => res.json()).then((data) => {
				isRefreshing = false;
				if (data) {
					if (data?.access_token && data?.refresh_token) {
						localStorage.setItem(AUTH_TOKEN, data.access_token);
						localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
						authenticated(data.access_token);
					} else {
						console.log('Falla la auth');
						localStorage.clear();
						window.location = '/';
						return Promise.reject(data);
					}
				}
				isRefreshing = false;
				const {access_token} = data;
				onRrefreshed(access_token);
			}).catch(e=>{
				console.log('Falla todo');
				localStorage.clear();
				window.location = '/';
				return Promise.reject(data);
			});
		}

		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(accessToken => {
				// replace the expired accessToken and retry
				// delete originalRequest.headers['authorization'];
				originalRequest.headers['authorization'] = 'Bearer ' + accessToken;
				resolve(axios(originalRequest));
			});
		});
	} else {
		return Promise.reject(data);
	}
});

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const  onRrefreshed = (accessToken) => {
	refreshSubscribers.map(cb => cb(accessToken));
}

export default service
