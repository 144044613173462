import {Form, Input, Button, notification, Row, Col, Switch} from 'antd';
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {CatalogsConfig} from "../catalogs.config";
import apiService from "../../../../services/ApiService";

const layout = {
    layout: 'vertical'
};

export function Funcionalidad({data}) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [configGlobal, setConfigGlobal] = useState({});
    const history = useHistory();

    useEffect(() => {
        const key = sessionStorage.getItem('key');
        if (key) {
            setConfigGlobal(CatalogsConfig[key]);
        }
    }, []);

    const onFinish = values => {
        setLoading(true);
        let dataSend = {};
        let url = configGlobal.url_register;
        if (data) {
            dataSend = {...data, ...(values) }
            url = configGlobal.url_update;
        } else {
            dataSend = values
        }

        apiService.setPost({ url, data: dataSend }).then(
            ({data}) => {
                const response = data;
                if (response.status === 'success') {
                    notification.success({ message: response?.message});
                    if (data) {
                        history.goBack();
                    } else {
                        onReset();
                    }
                } else {
                    setErrors(response?.data);
                    notification.warning({ message: response?.message});
                }
                setLoading(false);
            }
        ).catch(reason => {
            setLoading(false);
            notification.error({ duration: 3, message: reason?.message || 'Error inesperado al guardar el registro.'});
        })
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <>
            <Row justify='center'>
                <Col span={24} md={12} className='p-5'>
                    <Form
                        { ...layout }
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            label="Funcionalidad"
                            name="funNombre"
                            initialValue={data?.funNombre}
                            rules={[
                                { required: true, message: 'Por favor un nombre!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Descripción"
                            name="funDescription"
                            initialValue={data?.funDescription}
                            rules={[
                                { required: true, message: 'Por favor una descripción!' }
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>

                        {
                            data && <>
                                <Col className='px-2' span={24} md={12}>
                                    <Form.Item
                                        label="Activar o desactivar registro"
                                        name="statusRegister"
                                        initialValue={data?.statusRegister}
                                    >
                                        <Switch defaultChecked={data?.statusRegister || false}/>
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <Form.Item>
                            <Button loading={loading} className="mr-2" type="primary" htmlType="submit">
                                Guardar
                            </Button>
                            {
                                !data && <Button className="mr-2" htmlType="button" onClick={onReset}>Borrar</Button>
                            }
                        </Form.Item>

                    </Form>
                </Col>
            </Row>
        </>
    );
}
