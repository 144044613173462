import React, {useEffect, useState} from 'react'
import {Button, Card, Empty, message, Switch, Table, Tooltip} from 'antd';
import {CatalogsConfig} from "./catalogs.config";
import apiService from "../../../services/ApiService";
import {EditFilled} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {APP_PREFIX_CATALOG, APP_PREFIX_PATH} from "../../../configs/AppConfig";
import DragSortingTable  from "./table.dragable";

let configComponent = {};

export function CatalogComponent (props) {

    sessionStorage.removeItem('edit');

    const [dataTable, setDataTable] = useState([]);
    const [key, setKey] = useState('');
    const [tableColumns, setTableColumns] = useState([]);
    const history = useHistory();

    useEffect(() => {
        // vaciamos los datos
        setDataTable([]);
        const pathname = props.location.pathname.split('/');
        const key = pathname[pathname.length -1];
        sessionStorage.setItem('key', key);
        setKey(key);
        configComponent = CatalogsConfig[key];

        let localTableColumn = [];

        localTableColumn = [...configComponent?.tableColumns];

        if (!localTableColumn.find(value => (value.dataIndex === 'actions'))) {
            localTableColumn.push({
                title: '',
                dataIndex: 'actions',
                render: (_, elm) => (
                    <div style={{ width: "90px" }} className="text-right">
                        <Tooltip title="Editar">
                            <Button type="primary" className="mr-2" icon={<EditFilled />} onClick={() => {editRegister(elm)}} size="small"/>
                        </Tooltip>
                    </div>
                )
            });
        }

        setTableColumns(localTableColumn);

        getRegisters();

    }, [props]);

    const getRegisters = (page = 0) => {

        const url = `${configComponent?.url_list}?page=${page}`;

        apiService.setPost({ url, data: {} }).then(
            ({data}) => {
                const response = data;
                if (response.status === 'success') {
                    setDataTable(response?.data?.content || response?.data);
                } else {
                    setDataTable([]);
                }
            }
        ).catch(reason => {
            message.warn('Error en la solicitud.');
            setDataTable([]);
        })
    }

    const goRegister = () => {
        history.push(`${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/register`);
    }

    // Editar registro
    const editRegister = (item) => {
        sessionStorage.setItem('edit', JSON.stringify(item));
        history.push(`${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/update`);
    }

    // Eliminar registro
    const deleteRegister = (item) => {
        const url = `${configComponent?.url_delete}${item[configComponent.key]}`;
        apiService.setPost({ url, data: {} }).then(
            ({data}) => {
                const response = data;
                getRegisters();
            }
        ).catch(reason => {
            message.warn('Error en la solicitud.');
        });
    }

    // Order list
    const orderList = (registers) => {
        const dataSend = [];
        registers.forEach((register, key) => {
            dataSend.push({
                [configComponent.key]: register[configComponent.key],
                [configComponent.order]: (key + 1)
            });
        });
    }

    return (
        <>
            <div className='mb-3 d-flex d-inline-flex justify-content-end w-100'>
                <Button type="primary" onClick={goRegister}>Registrar</Button>
            </div>
            <Card bodyStyle={{'padding': '0px'}}>
                {
                    (dataTable.length > 0) ?
                    <>
                        {
                            (key !== 'product-line') ?
                                <Table pagination={false} columns={tableColumns} dataSource={dataTable} onChange={(e) => getRegisters((e.current - 1))} rowKey={configComponent?.key} />
                                :
                                <DragSortingTable data={dataTable} columns={tableColumns} orderList={orderList}/>
                        }
                    </> : <div style={{ padding: '5rem' }}>
                            <Empty />
                        </div>
                }
            </Card>
        </>
    );
}
