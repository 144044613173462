import fetch from 'auth/FetchInterceptor'

const apiService = {}

apiService.getPost = function (url, params) {
  return fetch({
    url,
    method: 'get',
    params
  })
}

apiService.setPost = ({url, data}) => {
  return fetch({
    url,
    method: 'post',
    data: data
  })
}

apiService.setPostData = ({url}) => {
  return fetch({
    url,
    method: 'post',
    data: null
  })
}

export default apiService
