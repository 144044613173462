import React  from 'react';
import {notification, Table} from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import apiService from "../../../services/ApiService";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class SortableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props?.data,
            columns: this.props?.columns
        };
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { dataSource } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            this.SaveOrderList(newData);
            this.props?.orderList(newData);
            this.setState({ dataSource: newData });
        }
    };

    SaveOrderList = (list) => {
        apiService.setPost({ url: '/servicios/order_linea_producto', data: list }).then(
            ({data}) => {
                const response = data;
                if (response.status === 'success') {
                    notification.success({ message: response?.message});
                } else {
                    notification.warning({ message: response?.message});
                }
            }
        ).catch(reason => {
            notification.error({ duration: 3, message: reason?.message || 'Error inesperado en el servidor.'});
        })
    }

    DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { dataSource } = this.state;
        const index = dataSource.findIndex(x => x.lprId === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    render() {
        const { dataSource, columns } = this.state;

        return (
            <Table
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="lprId"
                components={{
                    body: {
                        wrapper: this.DraggableContainer,
                        row: this.DraggableBodyRow,
                    },
                }}
            />
        );
    }
}

export default SortableTable;
