import fetch from 'auth/FetchInterceptor'

const JwtAuthService = {}

JwtAuthService.login = ({email, password}) => {

	const formData = new FormData();
	formData.append('username', email);
	formData.append('password', password);
	formData.append('grant_type', 'password');

	return fetch({
		url: '/security/oauth/token',
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Basic ' + btoa("LAAS:LEXIS_SERVICE_LAAS")
		},
		data: formData
	});
}

JwtAuthService.sendRecoveryAccount = function ({email}) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
			'public-request': 'true'
		},
		data: {email}
	})
}

export default JwtAuthService
