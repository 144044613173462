import React from "react";
import {sortableHandle} from "react-sortable-hoc";
import {MenuOutlined} from "@ant-design/icons";
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

export const CatalogsConfig = {
    'product-line': {
        key: 'lprId',
        order: 'lprOrden',
        url_list: '/servicios/get_linea_producto',
        url_register: '/servicios/save_linea_producto',
        url_update: '/servicios/update_linea_producto',
        url_delete: '/servicios/delete_linea_producto/',
        title_register: 'Nueva línea de producto',
        title_update: 'Editar línea de producto',
        components: {
          register: 'ProductLineRegister'
        },
        tableColumns: [
            {
                title: 'Orden',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title: 'Línea producto',
                dataIndex: 'lprNombre',
                render: (_, record) => (
                    <div>
                        <p className='m-0 pl-2'>
                            {
                                record.lprNombre
                            }
                        </p>
                    </div>
                )
            },
            {
                title: 'Color',
                dataIndex: 'lprColor',
                render: (_, record) => (
                    <div className='d-flex align-items-center'>
                        <div className='square' style={{backgroundColor: record.lprColor}}/>
                        <p className='m-0 pl-2'>
                            {
                                record.lprColor
                            }
                        </p>
                    </div>
                ),
            },
            {
                title: 'Descuento',
                dataIndex: 'lprDescuento'
            },
            {
                title: 'Estado',
                dataIndex: 'statusRegister',
                render: (_, record) => (
                    <p className='m-0'>
                        {
                            record.statusRegister ? <small style={{color: "green"}}>Activo</small> : <small style={{color: "orange"}}>Inactivo</small>
                        }
                    </p>
                )
            },
        ]
    },
    package: {
        key: 'paqId',
        url_list: '/servicios/get_paquetes',
        url_register: '/servicios/save_paquete',
        url_update: '/servicios/update_paquete',
        url_delete: '/servicios/delete_paquete/',
        title_register: 'Nuevo paquete',
        title_update: 'Editar paquete',
        tableColumns: [
            {
                title: 'Paquete',
                dataIndex: 'paqNombre',
                sorter: {
                    compare: (a, b) => {
                        a = a.paqNombre.toLowerCase();
                        b = b.paqNombre.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: 'Accesos',
                dataIndex: 'paqAccesos',
            },
            {
                title: 'Estado',
                dataIndex: 'statusRegister',
                render: (_, record) => (
                    <p className='m-0'>
                        {
                           record.statusRegister ? <small style={{color: "green"}}>Activo</small> : <small style={{color: "orange"}}>Inactivo</small>
                        }
                    </p>
                )
            },
        ]
    },
    validity: {
        key: 'vigId',
        url_list: '/servicios/get_vigencias',
        url_register: '/servicios/save_vigencia',
        url_update: '/servicios/update_vigencia',
        url_delete: '/servicios/delete_vigencia/',
        title_register: 'Nueva vigencia',
        title_update: 'Editar vigencia',
        tableColumns: [
            {
                title: 'Vigencia',
                dataIndex: 'vigNombre',
                sorter: {
                    compare: (a, b) => {
                        a = a.vigNombre.toLowerCase();
                        b = b.vigNombre.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: 'Meses',
                dataIndex: 'vigMeses',
            },
            {
                title: 'Estado',
                dataIndex: 'statusRegister',
                render: (_, record) => (
                    <p className='m-0'>
                        {
                            record.statusRegister ? <small style={{color: "green"}}>Activo</small> : <small style={{color: "orange"}}>Inactivo</small>
                        }
                    </p>
                )
            },
        ]
    },
    campaigns: {
        key: 'camId',
        url_list: '/servicios/get_campaigns',
        url_register: '/servicios/save_campaign',
        url_update: '/servicios/update_campaign',
        url_delete: '/servicios/delete_campaign/',
        title_register: 'Nueva campaña',
        title_update: 'Editar campaña',
        tableColumns: [
            {
                title: 'Campaña',
                dataIndex: 'camNombre',
                sorter: {
                    compare: (a, b) => {
                        a = a.camNombre.toLowerCase();
                        b = b.camNombre.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: 'Descuento',
                dataIndex: 'camDescuento',
            },
            {
                title: 'Estado',
                dataIndex: 'statusRegister',
                render: (_, record) => (
                    <p className='m-0'>
                        {
                            record.statusRegister ? <small style={{color: "green"}}>Activo</small> : <small style={{color: "orange"}}>Inactivo</small>
                        }
                    </p>
                )
            },
        ]
    },
    funcionalidad: {
        key: 'funId',
        url_list: '/servicios/get_funcionalidad',
        url_register: '/servicios/save_funcionalidad',
        url_update: '/servicios/update_funcionalidad',
        url_delete: '/servicios/delete_funcionalidad/',
        title_register: 'Nueva funcionalidad',
        title_update: 'Editar funcionalidad',
        tableColumns: [
            {
                title: 'Funcionalidad',
                dataIndex: 'funNombre',
                sorter: {
                    compare: (a, b) => {
                        a = a.funNombre.toLowerCase();
                        b = b.funNombre.toLowerCase();
                        return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: 'Estado',
                dataIndex: 'statusRegister',
                render: (_, record) => (
                    <p className='m-0'>
                        {
                            record.statusRegister ? <small style={{color: "green"}}>Activo</small> : <small style={{color: "orange"}}>Inactivo</small>
                        }
                    </p>
                )
            },
        ]
    },
};