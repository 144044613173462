import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
    EditOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
    {
        title: "Editar perfil",
        icon: EditOutlined ,
        path: "/app/profile"
    },
]

export const NavProfile = ({signOut}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const profileImg = "";
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    {
                        (profileImg) ?
                            <Avatar size={45} src={profileImg} />
                            :
                            <Avatar style={{ color: '#ffffff', backgroundColor: '#233442' }} size={45} >{user?.usuNombre?.charAt(0) + user?.usuApellido?.charAt(0)}</Avatar>
                    }
                    <div className="pl-3">
                        <h4 className="mb-0">{user?.usuNombre} {user?.usuApellido}</h4>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon className="mr-3" type={el.icon} />
                                    <span className="font-weight-normal">{el.title}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.legth + 1} onClick={e => signOut()}>
                        <span>
                          <LogoutOutlined className="mr-3"/>
                          <span className="font-weight-normal">Cerrar sesión</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    {
                        (profileImg) ?
                            <Avatar src={profileImg} />
                            :
                            <Avatar style={{ color: '#ffffff', backgroundColor: '#233442' }}>{user?.usuNombre?.charAt(0) + user?.usuApellido?.charAt(0)}</Avatar>
                    }
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, {signOut})(NavProfile)
