import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {CatalogsConfig} from "./catalogs.config";
import {Button, Card, Col, Row} from "antd";
import {ProductLineRegister} from "./Components/product.line.register";
import {Package} from "./Components/package.register";
import {Validity} from "./Components/validity.register";
import {Campaign} from "./Components/campaign.register";
import {Funcionalidad} from "./Components/funcionalidad.register";

export function CatalogUpdate() {
    const [keyForm, setKeyForm] = useState('');
    const [dataEdit, setDataEdit] = useState({});
    const [configGlobal, setConfigGlobal] = useState({});
    const history = useHistory();

    useEffect(() => {
        const key = sessionStorage.getItem('key');
        if (key) {
            if (sessionStorage.getItem('edit')) {
                setDataEdit(JSON.parse(sessionStorage.getItem('edit')));
            }
            setKeyForm(key);
            setConfigGlobal(CatalogsConfig[key]);
        } else {
            history.goBack();
        }
    }, []);

    const goBack = () => {
        history.goBack();
    }

    return (
        <>
            <Row>
                <Col span={24} className='pt-4 pl-4'>
                    <h2>{configGlobal?.title_update}</h2>
                </Col>
                <Col span={24}>
                    <div className='mb-3 d-flex d-inline-flex justify-content-end w-100'>
                        <Button type="primary" ghost onClick={goBack}>Volver a la lista</Button>
                    </div>
                </Col>
            </Row>
            <Card bodyStyle={{'padding': '0px'}}>
                { (keyForm === 'product-line') && <ProductLineRegister data={dataEdit}/> }
                { (keyForm === 'package') && <Package data={dataEdit}/> }
                { (keyForm === 'validity') && <Validity data={dataEdit}/> }
                { (keyForm === 'campaigns') && <Campaign data={dataEdit}/> }
                { (keyForm === 'funcionalidad') && <Funcionalidad data={dataEdit} /> }
            </Card>
        </>
    );
}