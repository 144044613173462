import {
  DashboardOutlined,
  UserOutlined,
  BarsOutlined,
  CheckSquareFilled,
  CalendarFilled,
  AlertFilled,
  DashboardFilled,
  RobotOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined
} from '@ant-design/icons';
import {APP_PREFIX_CATALOG, APP_PREFIX_PATH} from 'configs/AppConfig'

let dashBoardNavTree = []

const user = JSON.parse(localStorage.getItem('user'));

if (user === undefined) {
  localStorage.clear();
  document.location.reload();
} else {
  if (user?.authorities && user?.authorities[0] !== 'ROLE_COMERCIAL') {
    dashBoardNavTree = [
      {
        key: 'admin',
        title: 'admin',
        path: `${APP_PREFIX_PATH}/pages`,
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'admin-user',
            title: 'sidenav.pages.user',
            icon: RobotOutlined,
            path: `${APP_PREFIX_PATH}/accounts`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'user-list',
            title: 'sidenav.pages.user.list',
            icon: UserOutlined,
            path: `${APP_PREFIX_PATH}/users-list`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'admin-user-list',
            title: 'sidenav.pages.admin.user.list',
            icon: UsergroupAddOutlined,
            path: `${APP_PREFIX_PATH}/admin-users-list`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'orders-list',
            title: 'sidenav.pages.orders',
            icon: ShoppingOutlined,
            path: `${APP_PREFIX_PATH}/orders`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'product',
            path: '',
            title: 'sidenav.pages.products',
            icon: DashboardFilled,
            breadcrumb: true,
            submenu: [
              {
                key: 'product-list',
                path: `${APP_PREFIX_PATH}/products`,
                title: 'sidenav.pages.product.list',
                icon: '',
                breadcrumb: true,
                submenu: []
              },
              {
                key: 'product-register',
                path: `${APP_PREFIX_PATH}/product-register`,
                title: 'sidenav.pages.product.register',
                icon: '',
                breadcrumb: true,
                submenu: []
              },
            ]
          }
        ]
      },
      {
        key: 'catalogs.title.name',
        title: 'catalogs.title.name',
        path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}`,
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'catalogs.title.line.product.list',
            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/product-line`,
            title: 'catalogs.title.line',
            icon: BarsOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'catalogs.title.package.list',
            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/package`,
            title: 'catalogs.title.package',
            icon: CheckSquareFilled,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'catalogs.title.validity.list',
            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/validity`,
            title: 'catalogs.title.validity',
            icon: CalendarFilled,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'catalogs.title.campaigns.list',
            path: `${APP_PREFIX_PATH}${APP_PREFIX_CATALOG}/campaigns`,
            title: 'catalogs.title.campaigns',
            icon: AlertFilled,
            breadcrumb: true,
            submenu: []
          }
        ]
      },
    ]
  } else {
    dashBoardNavTree = [
      {
        key: 'admin',
        title: 'admin',
        path: `${APP_PREFIX_PATH}/pages`,
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'admin-user',
            title: 'sidenav.pages.user',
            icon: RobotOutlined,
            path: `${APP_PREFIX_PATH}/accounts`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'user-list',
            title: 'sidenav.pages.user.list',
            icon: UserOutlined,
            path: `${APP_PREFIX_PATH}/users-list`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'orders-list',
            title: 'sidenav.pages.orders',
            icon: ShoppingOutlined,
            path: `${APP_PREFIX_PATH}/orders`,
            breadcrumb: true,
            submenu: []
          }
        ]
      },
    ];
  }
}

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
